
/**
 * @roxi/routify 2.18.11
 * File generated Thu Nov 14 2024 13:39:09 GMT+0000 (Coordinated Universal Time)
 */

export const __version = "2.18.11"
export const __timestamp = "2024-11-14T13:39:09.193Z"

//buildRoutes
import { buildClientTree } from "@roxi/routify/runtime/buildRoutes"

//imports


//options
export const options = {}

//tree
export const _tree = {
  "root": true,
  "children": [
    {
      "isFallback": true,
      "path": "/_fallback",
      "component": () => import('../src/pages/_fallback.svelte').then(m => m.default)
    },
    {
      "isPage": true,
      "path": "/contattaci",
      "id": "_contattaci",
      "component": () => import('../src/pages/contattaci.svelte').then(m => m.default)
    },
    {
      "isPage": true,
      "path": "/cronologia",
      "id": "_cronologia",
      "component": () => import('../src/pages/cronologia.svelte').then(m => m.default)
    },
    {
      "isDir": true,
      "ext": "",
      "children": [
        {
          "isDir": true,
          "ext": "",
          "children": [
            {
              "isIndex": true,
              "isPage": true,
              "path": "/dichiarazione/app/index",
              "id": "_dichiarazione_app_index",
              "component": () => import('../src/pages/dichiarazione/app/index.svelte').then(m => m.default)
            }
          ],
          "path": "/dichiarazione/app"
        },
        {
          "isIndex": true,
          "isPage": true,
          "path": "/dichiarazione/index",
          "id": "_dichiarazione_index",
          "component": () => import('../src/pages/dichiarazione/index.svelte').then(m => m.default)
        },
        {
          "isDir": true,
          "ext": "",
          "children": [
            {
              "isIndex": true,
              "isPage": true,
              "path": "/dichiarazione/siti/index",
              "id": "_dichiarazione_siti_index",
              "component": () => import('../src/pages/dichiarazione/siti/index.svelte').then(m => m.default)
            }
          ],
          "path": "/dichiarazione/siti"
        }
      ],
      "path": "/dichiarazione"
    },
    {
      "isPage": true,
      "path": "/errori-approfondito",
      "id": "_erroriApprofondito",
      "component": () => import('../src/pages/errori-approfondito.svelte').then(m => m.default)
    },
    {
      "isPage": true,
      "path": "/errori-semplificato",
      "id": "_erroriSemplificato",
      "component": () => import('../src/pages/errori-semplificato.svelte').then(m => m.default)
    },
    {
      "isIndex": true,
      "isPage": true,
      "path": "/index",
      "id": "_index",
      "component": () => import('../src/pages/index.svelte').then(m => m.default)
    },
    {
      "isPage": true,
      "path": "/media-policy",
      "id": "_mediaPolicy",
      "component": () => import('../src/pages/media-policy.svelte').then(m => m.default)
    },
    {
      "isPage": true,
      "path": "/monitoraggio-approfondito",
      "id": "_monitoraggioApprofondito",
      "component": () => import('../src/pages/monitoraggio-approfondito.svelte').then(m => m.default)
    },
    {
      "isPage": true,
      "path": "/monitoraggio-semplificato",
      "id": "_monitoraggioSemplificato",
      "component": () => import('../src/pages/monitoraggio-semplificato.svelte').then(m => m.default)
    },
    {
      "isPage": true,
      "path": "/note-legali",
      "id": "_noteLegali",
      "component": () => import('../src/pages/note-legali.svelte').then(m => m.default)
    },
    {
      "isPage": true,
      "path": "/obiettivi",
      "id": "_obiettivi",
      "component": () => import('../src/pages/obiettivi.svelte').then(m => m.default)
    },
    {
      "isPage": true,
      "path": "/opendata",
      "id": "_opendata",
      "component": () => import('../src/pages/opendata.svelte').then(m => m.default)
    },
    {
      "isPage": true,
      "path": "/privacy-policy",
      "id": "_privacyPolicy",
      "component": () => import('../src/pages/privacy-policy.svelte').then(m => m.default)
    },
    {
      "isPage": true,
      "path": "/progetto",
      "id": "_progetto",
      "component": () => import('../src/pages/progetto.svelte').then(m => m.default)
    }
  ],
  "isLayout": true,
  "path": "/",
  "id": "__layout",
  "component": () => import('../src/pages/_layout.svelte').then(m => m.default)
}


export const {tree, routes} = buildClientTree(_tree)

